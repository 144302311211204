@if (isInput) {
  <p-floatLabel>
    <input
      class="capitalize"
      type="text"
      [value]="DFActive ? ('primeng.' + DFActive | translate) : rangeDateToString()"
      [readOnly]="true"
      pInputText
      (click)="onToggleVisible()" />
    <label
      >{{ title }}
      @if (required) {
        <span class="text-red-600">*</span>
      }
    </label>
  </p-floatLabel>
} @else {
  <span class="p-input-icon-right">
    <div
      (click)="onToggleVisible()"
      class="pl-3 pr-2 h-8 border rounded-full flex items-center gap-1 cursor-pointer hover:border-blue-600">
      <span class="font-semibold w-max"
        >{{ title ? title : ('common.date-range' | translate) }}{{ DFActive || rangeDateToString() ? ': ' : '' }}</span
      >
      <div class="flex gap-0.5 items-center">
        @if (DFActive) {
          <span class="capitalize w-max"
            >{{ 'primeng.' + DFActive | translate }}
            @if (rangeDateToString()) {
              ({{ rangeDateToString() }})
            }
          </span>
        } @else {
          <span class="w-max">{{ rangeDateToString() }}</span>
        }

        <i class="icon-wl-arrow-drop-down text-6"></i>
      </div>
    </div>
  </span>
}

<p-overlay [(visible)]="visible" [appendTo]="appendTo">
  <div class="h-full flex bg-white border rounded-xl shadow-lg mt-1" (click)="$event.stopPropagation()">
    <div class="h-auto flex flex-col gap-2 py-4 pl-5 pr-4 border-r">
      <label
        *ngFor="let item of DF"
        class="py-1 font-medium hover:text-primary-600 cursor-pointer min-w-max capitalize"
        [ngClass]="item.value === DFSelected ? 'font-medium text-primary-600' : ''"
        (click)="filterDate(item.value)">
        {{ 'primeng.' + item.value | translate }}
      </label>
    </div>
    <div class="h-full flex flex-col">
      <p-calendar
        styleClass="custom-calendar"
        [(ngModel)]="rangeDates"
        (ngModelChange)="getDateFilterArray($event)"
        (onSelect)="onSelect()"
        selectionMode="range"
        [numberOfMonths]="2"
        [dateFormat]="th.formatDate(true)"
        [showIcon]="true"
        [inline]="true"
        [minDate]="minDate"
        [maxDate]="maxDate"></p-calendar>
      <div class="flex gap-2 justify-between p-4 border-t">
        <span class="flex gap-2 items-center">
          <p-calendar
            [minDate]="minDate"
            [maxDate]="maxDate"
            [(ngModel)]="dateFrom"
            (onInput)="onInputDate()"
            (change)="setDateFilter()"
            ngClass="w-[10rem]"
            [showOnFocus]="false"
            [dateFormat]="th.formatDate(true)"></p-calendar>
          <span>-</span>
          <p-calendar
            [minDate]="minDate"
            [maxDate]="maxDate"
            [(ngModel)]="dateTo"
            (onInput)="onInputDate()"
            (change)="setDateFilter()"
            ngClass="w-[10rem]"
            [showOnFocus]="false"
            [dateFormat]="th.formatDate(true)"></p-calendar>
        </span>
        <span class="flex gap-2">
          <app-button
            [label]="'action.cancel' | translate"
            variant="outline-secondary"
            (onClick)="cancelDateFilter()"></app-button>
          <app-button [label]="'action.apply' | translate" (onClick)="applyDateFilter()"></app-button>
        </span>
      </div>
    </div>
  </div>
</p-overlay>
